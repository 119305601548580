import React, { useEffect } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import MainCard from 'components/MainCard';
import { useSelector, useDispatch } from 'react-redux';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import config from 'config';
import fetchPlanInfo from 'services/proAccountServices';
import { useNavigate } from 'react-router-dom';

const euCountryCodes = [
    "AT", "BE", "BG", "HR", "CY", "CZ",
    "DK", "EE", "FI", "FR", "DE", "GR", "HU",
    "IE", "IT", "LV", "LT", "LU", "MT",
    "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"
];

const CheckoutRedirect = () => {
  const dispatch = useDispatch();
  const plan = useSelector((state) => state.proAccountReducer.plan);
  const auth = getAuth();
  const apiUrl = config.apiUrl;
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const getCountry = async () => {
      try {
        const response = await fetch("https://ipinfo.io/json?token=4f1c89b6de8103");
        const jsonResponse = await response.json();
        const countryCode = jsonResponse.country;
        return euCountryCodes.includes(countryCode);
      } catch (error) {
        console.error('Error fetching country:', error);
        return false; // Default to non-EU pricing if there's an error
      }
    };

    const initializeCheckout = async () => {
      // First, wait for the plan info
      await fetchPlanInfo(dispatch);
      console.log('plan', plan);
       // 2. If plan isn't loaded yet, don't proceed
       if (!plan || !isMounted) return;
      // Now that we have the plan info, we can proceed
      if (plan !== 'Free') {
        navigate('/lp-analysis');
        return;
      }

      // Only proceed with checkout if plan is Free
      const isEuropean = await getCountry().catch(() => false);
      const token = await auth.currentUser.getIdToken();
      const priceId = isEuropean ? config.priceIdProEUMonth : config.priceIdProUSMonth;
      
      const createCheckoutSession = async (includeCoupon = true) => {
        const urlParams = new URLSearchParams(window.location.search);
        const couponCode = includeCoupon ? urlParams.get('discount_code') : null;
        const trial_days = urlParams.get('trial_days') ?? 'true'; // Default to 'true' if not specified
        const endpointUrl = `${apiUrl}/pro_plans/create_checkout_session/${priceId}?trial_days=${trial_days}${couponCode ? `&discount_code=${couponCode}` : ''}`;
        
        return axios.get(endpointUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
      };

      try {
        const response = await createCheckoutSession();

        if (window.gtag) {
          window.gtag('event', 'conversion', {
            'send_to': 'AW-16481265161/uSXlCMCs_9YZEInM8LI9',
            'value': 39.0,
            'currency': 'EUR'
          });
        }
        if (window.ym) {
          window.ym(94496474, 'reachGoal', 'START_TRIAL_CLICK_FROM_WELCOME');
        }

        if (response.data.url && response.status === 200) {
          window.location.href = response.data.url;
        }
      } catch (axiosError) {
        console.error('Checkout error:', axiosError);
        
        // If error is related to invalid coupon, retry without coupon
        if (axiosError.response?.status === 400 && 
            axiosError.response?.data?.error?.includes('No such coupon')) {
          try {
            const retryResponse = await createCheckoutSession(false);
            if (retryResponse.data.url && retryResponse.status === 200) {
              window.location.href = retryResponse.data.url;
            }
          } catch (retryError) {
            console.error('Retry checkout error:', retryError);
            // Handle final error (maybe show an error message to user)
          }
        }
      }
    };

    initializeCheckout();
    return () => { isMounted = false; };
  }, [plan, dispatch, auth, apiUrl, navigate]);

  return (
    <MainCard>
      <Box sx={{ textAlign: 'center', py: 5 }}>
        <Typography variant="h3" gutterBottom>
          Redirecting to checkout...
        </Typography>
        <Typography variant="body1" paragraph>
          Please wait while we prepare your trial activation.
        </Typography>
        <CircularProgress />
      </Box>
    </MainCard>
  );
};

export default CheckoutRedirect;
