import { lazy } from 'react';

import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import CommonLayout from 'layout/CommonLayout';
import AdminRoutes from './AdminRoutes';

import Loadable from 'components/Loadable';
const PagesLanding = Loadable(lazy(() => import('pages/landing')));
const InterviewsLanding = Loadable(lazy(() => import('pages/inter')));
const AndrewChenWtfAnalysis = Loadable(lazy(() => import('pages/lead-magnets/andrew-chen-wtf-analysis1')));
const MajaVojeBeachhead = Loadable(lazy(() => import('pages/lead-magnets/maja-voje-beachhead')));
// const ExperimentPagesLanding = Loadable(lazy(() => import('pages/experiment-landing')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '/',
          element: <PagesLanding />
        },
        // {
        //   path: '/ab-1',
        //   element: <ExperimentPagesLanding />
        // }
      ]
    },
    {
      path: '/interviews-landing',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '',
          element: <InterviewsLanding />
        }
      ]
    },
    {
      path: '/andrew-chen-wtf-analysis',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '',
          element: <AndrewChenWtfAnalysis />
        },
        {
          path: ':taskId',
          element: <AndrewChenWtfAnalysis />
        }
      ]
    },
    {
      path: '/maja-voje-beachhead',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '',
          element: <MajaVojeBeachhead />
        },
        {
          path: ':taskId',
          element: <MajaVojeBeachhead />
        }
      ]
    },
    AdminRoutes,
    LoginRoutes, MainRoutes]);
}
